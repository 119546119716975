/**
 * Navbar
 */

export default function() {

    const navbar = document.querySelector('[data-component="navbar"]');
    const mobnav = document.querySelector('.nav-list--dropdown');
    const menuIcon = document.querySelector('.menu-container');

    // When navbar exists
    if (navbar) {

        $('#main-menu').change(function(){
            mobnav.classList.toggle('is-visible');
            menuIcon.classList.toggle('is-menu-open');
        });

        $('.nav-list--dropdown > a').click(function(){
            mobnav.classList.toggle('is-visible');
            menuIcon.classList.toggle('is-menu-open');
        });
    }
}