/**
 * App
*/

import $ from "jquery";
import 'Components/accordion.js';
import 'Components/micromodal.js';
import AOS from "../../node_modules/aos/dist/aos";
import navbar from "Components/navbar";


document.addEventListener('DOMContentLoaded', function() {
    window.$ = $;

    AOS.init({
        offset: -210,
    });

    navbar();


    var slidingMenu = function() {
        var menuToggle = document.querySelector('.menu-toggle');
        var body       = document.body;

        menuToggle.addEventListener('click', function(ev) {
        ev.preventDefault();
            body.classList.toggle('menu-active');
            menuToggle.classList.toggle('menu-on');
        });
    }

    slidingMenu();

    $('.resi-nav').change(function() {//do something when the user clicks the box
        $('.sub-lenu-lvl-re').toggleClass('rotate');
    });

    $('.dc-nav').change(function() {//do something when the user clicks the box
        $('.sub-lenu-lvl-dc').toggleClass('rotate');
    });

    $('.dud').change(function() {//do something when the user clicks the box
        $('.dashboard-user-nav__item').toggleClass('rotate');
    });

});