
$('.accordion span').click(function(j) {
    var dropDown = $(this).closest('li').find('p');

    $(this).closest('.accordion').find('p').not(dropDown).slideUp();

    if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).closest('.accordion').find('li.active').removeClass('active');
    } else {
        $(this).closest('.accordion').find('span.active').removeClass('active');
        $(this).closest('.accordion').find('li.active').removeClass('active');

        $(this).toggleClass('active');
        $(this).parent().toggleClass('active');
    }

    dropDown.stop(false, true).slideToggle();

    j.preventDefault();
});
